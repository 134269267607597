<template>
  <div id="nav">
    <navBar/>
    
  </div>
  <router-view class="router"/>
  <footerSite class="router"/>
</template>


<script>

import navBar from '@/components/navBar';
import footerSite from '@/components/footer';
export default {
  components: {
    navBar,
    footerSite,
  }
}
</script>

<style>
html, body {
  overflow-x: hidden;
}
body {
  position: relative
}
</style>
