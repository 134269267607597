<template>
    <header class="header">
        <nav class="navBar">
            <a href="/"><img class="logoHeader" src="@/assets/img/logo.svg" alt="Logo etc Academy"></a>
            <div class="mobile-menu">
                <img src="@/assets/img/menu.svg" alt="menu" @click="addClickEvent()">
            </div>
            <ul class="nav-list" >
                <li id='nav-x'><svg @click="addClickEvent()" xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
</svg></li>
                <li class="nav-item nav-home"><a class="nav-link linksNavBar inicio" href="/">Inicio</a></li>
                <li class="nav-item"><a class="nav-link linksNavBar palestras" href="/palestras">Palestras</a></li>
                <li class="nav-item"><a class="nav-link linksNavBar workshops" href="/workshops">Workshops</a></li>
                <li class="nav-item"><a class="nav-link linksNavBar customizados" href="/customizados">Soluções customizadas</a></li>
                <li class="nav-item"><a class="nav-link linksNavBar sobre" href="/sobre">Sobre nós</a></li>
                <li class="nav-item"><a class="nav-link linksNavBar contato" href="#contato">contato</a></li>
                <div class="nav-contato">
                    <li><a class="nav-link links linksNavBar" target="_blank" href="https://api.whatsapp.com/send?phone=5531998316905&text=Ol%C3%A1%2C%20cheguei%20at%C3%A9%20voc%C3%AA%20atrav%C3%A9s%20do%20site.%20Quero%20obter%20mais%20informa%C3%A7%C3%B5es%20sobre%3A%20"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
                      <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
                    </svg>  31 99999-9999</a></li>
                    <li><a class="nav-link links linksNavBar" target="_blank" href="mailto:alysson@etcdigital.etc.br"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope-fill" viewBox="0 0 16 16">
                      <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"/>
                    </svg> alysson@etcdigital.etc.br</a></li>
                </div>
            </ul>
        </nav>
    </header>
</template>

<script>
export default {
    data(){
        return{
            display: true
        }
        
    },

    created(){
        const root = document.documentElement
        console.log(window.location.pathname)
        if(window.location.pathname == '/'){
            root.style.setProperty('--inicio',  '#F18A00')
            }
        else if(window.location.pathname == '/palestras'){
                root.style.setProperty('--palestras',  '#F18A00')
            }
        else if(window.location.pathname == '/workshops'){
                root.style.setProperty('--workshops',  '#F18A00')
            }
        else if(window.location.pathname == '/customizados'){
                root.style.setProperty('--customizados',  '#F18A00')
            }
        else if(window.location.pathname == '/sobre'){
            root.style.setProperty('--sobre',  '#F18A00')
        }
        
    },
    methods:{


        addClickEvent(){
            
            const root = document.documentElement
            // const mobileMenu = document.querySelector('mobile-menu')
            const navList = this.$el.querySelector('.nav-list')
            // const navLinks = document.querySelector('links')
            // const activeClass = 'active'
            if(this.display){
                navList.classList.add("active")
                root.style.setProperty('--display', 'none')
                this.display = false
                document.documentElement.style.height = '100%';
            }
            else{
                navList.classList.remove("active")
                root.style.setProperty('--display', 'block')
                this.display = true
            }
            
        }
    }
}
</script>

<style>
  

:root {
  --display: block;
  --inicio: #050530;
  --palestras: #050530;
  --workshops: #050530;
  --customizados: #050530;
  --sobre: #050530;
}
#nav-x{
    display: none;
}
.nav-contato{
        display: none;
    }
.linksNavBar{
   
    color: #050530;

    font-size: 15px;
    
}

.linksNavBar:hover{
    color: #F18A00;
}

.logoHeader{
    
    max-height: 40px;
}
.navBar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    font-family:  'Hurme', sans-serif;
    height: 8vh;
}

.nav-list{
    list-style: none;
    display: flex;
    
    padding: 0;
    margin: 0;
    
}

.header{
    margin: 4vh 15vw;
}

.mobile-menu{
    cursor: pointer;
    display: none;
}


 .inicio{color: var(--inicio);}
 .palestras{color: var(--palestras);}
 .workshops{color: var(--workshops);}
.customizados{color: var(--customizados);}
.sobre{color: var(--sobre);}

@media (max-width: 1199px) {
    .linksNavBar{
   padding: 7px;

      
}
.logoHeader{
    
    max-height: 30px;
}
}

@media (max-width: 999px) {
    body {
        height: 100%;
    }
  .header{
  margin: 4vh 5vw;
  
}
    .nav-list{
        position: absolute;
        top: 0;
        right: 0;
        width: 100vw;
        height: 100vw;
        background-color: #FFFFFF;
        flex-direction: column;
        transform: translateX(100%);
    }
    .linksNavBar{
        
        color: #6B787F;
        font-size: 18px;
        padding-left: 10vh;
    }

    .mobile-menu{
        display: block;
    }
    *.active{
        height: 90%;
    }
    .nav-list.active{
        transform: translateX(0);
    }
    
    .router{
        display: var(--display);
    }

    .nav-home{
        margin-top: 5vh;
    }

    #nav-x{
        cursor: pointer;
        display: flex;
        justify-content: end;
        margin: 10vh 10vw 0 0 ;
    }
    .nav-contato{
            
        display: block;
        position: absolute;
        
        top: 80vh;;
        right: 0;
        width: 100vw;
        
        background-color: #FFFFFF;
        flex-direction: column;
       
    }
    .logoHeader{
    
    max-height: 30px;
}
.header{
    margin: 1vh vw;
}
.contato{
    display: none;
}
}
</style>