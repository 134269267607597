<template>
<section id="section3">
      <swiper 
    id='swiper1'
    :modules="modules"
    :slides-per-view="slides"
    :space-between="1"
    navigation
    :pagination="{ clickable: true }"
    :scrollbar="{ draggable: true }"
    :autoplay="autoplay"
    @swiper="onSwiper"
    @slideChange="onSlideChange"
  >
  
    <swiper-slide><img class="swiperIcon" src="@/assets/img/logos/sebrae.svg" alt=""></swiper-slide>
    <swiper-slide><img class="swiperIcon" src="@/assets/img/logos/vl.svg" alt=""></swiper-slide>
    <swiper-slide><img class="swiperIcon" src="@/assets/img/logos/trt.svg" alt=""></swiper-slide>
    <swiper-slide><img class="swiperIcon" src="@/assets/img/logos/cdl.svg" alt=""></swiper-slide>
    <swiper-slide><img class="swiperIcon" src="@/assets/img/logos/arcelor.svg" alt=""></swiper-slide>
    <swiper-slide><img class="swiperIcon" src="@/assets/img/logos/correios.svg" alt=""></swiper-slide>
    <swiper-slide><img class="swiperIcon" src="@/assets/img/logos/IFSULDEMINAS.svg" alt=""></swiper-slide>
    <swiper-slide><img class="swiperIcon" src="@/assets/img/logos/SESCOOP.svg" alt=""></swiper-slide>
    ...
  </swiper>
  <swiper 
    id='swiper2'
    :modules="modules"
    :slides-per-view="2"
    :space-between="1"
    navigation
    :pagination="{ clickable: true }"
    :scrollbar="{ draggable: true }"
    :autoplay="autoplay"
    @swiper="onSwiper"
    @slideChange="onSlideChange"
  >
    <swiper-slide><img class="swiperIcon" src="@/assets/img/logos/sebrae.svg" alt=""></swiper-slide>
    <swiper-slide><img class="swiperIcon" src="@/assets/img/logos/vl.svg" alt=""></swiper-slide>
    <swiper-slide><img class="swiperIcon" src="@/assets/img/logos/trt.svg" alt=""></swiper-slide>
    <swiper-slide><img class="swiperIcon" src="@/assets/img/logos/cdl.svg" alt=""></swiper-slide>
    <swiper-slide><img class="swiperIcon" src="@/assets/img/logos/arcelor.svg" alt=""></swiper-slide>
    <swiper-slide><img class="swiperIcon" src="@/assets/img/logos/correios.svg" alt=""></swiper-slide>
    <swiper-slide><img class="swiperIcon" src="@/assets/img/logos/IFSULDEMINAS.svg" alt=""></swiper-slide>
    <swiper-slide><img class="swiperIcon" src="@/assets/img/logos/SESCOOP.svg" alt=""></swiper-slide>
    ...
  </swiper>
    </section>
</template>

  

<script>

  import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
  import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
  import 'swiper/swiper.min.css';
  import 'swiper/modules/autoplay/autoplay.min.css'

  export default {
    components: {
      Swiper,
      SwiperSlide,
    },
    data(){
      return{
        with: this.withSize(),
        slides: 4.5,
        autoplay: {
        delay: 2000,
      },
      }
    },

    methods:{
    withSize(){
        return window.innerWidth
      }
      
  },

    setup() {
      return {
        modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay],
      };
    },
  };
</script>

<style scoped>
#section3{
  margin-top: 30px;
  max-width: 65vw;
}

.swiperIcon{
  width: 120px;
}

#swiper2{
  display: none;
}
@media (max-width: 900px) {
  :root{
    --width: 900;
}
#swiper1{
  display: none;
}
#swiper2{
  display: block;
}
}
</style>