<template>
    <footer>
        <div class="caixa">
            <a href="http://www.etcdigital.etc.br/"><img class="logo-footer" src="@/assets/img/logo-white.svg" alt=""></a>
            <div id="address">
                <h3>Endereço</h3>
                <p>Rua Sebastião Stokler, 140 - Estoril - Belo Horizonte/MG, 30.494-430</p>
            </div>
            <div id="l1">
                <ul>
                    <li><a href="https://api.whatsapp.com/send?phone=5531998316905&text=Ol%C3%A1%2C%20cheguei%20at%C3%A9%20voc%C3%AA%20atrav%C3%A9s%20do%20site.%20Quero%20obter%20mais%20informa%C3%A7%C3%B5es%20sobre%3A%20" target="_blank" rel="noopener noreferrer"><img class="icon" src="@/assets/img/whatsapp.svg" alt="whatsapp">31 99831-6905</a></li>
                    <li><a href="https://www.facebook.com/etcedigital" target="_blank" rel="noopener noreferrer"><img class="icon" src="@/assets/img/facebook.svg" alt="facebook">/etcedigital</a></li>
                    <li><a href="https://www.linkedin.com/in/alyssonlisboa/" target="_blank" rel="noopener noreferrer"><img class="icon" src="@/assets/img/linkedin.svg" alt="linkedin">in/alyssonlisboa/</a></li>
                </ul>
            </div>
            <div id="l2">
                <ul>
                    <li><a href="mailto:alysson@etcdigital.etc.br"><img class="icon" src="@/assets/img/email.svg" alt="email">alysson@etcdigital.etc.br</a></li>
                    <li><a href="https://www.instagram.com/etcdigital_/" target="_blank" rel="noopener noreferrer"><img class="icon" src="@/assets/img/instagram.svg" alt="instagram">etcdigital_</a></li>
                    <li><a href="https://open.spotify.com/show/6u4pesa1L3mUvsHdkkKHCu?si=pIz7fOQNQLeZTKqSezDWUw&dl_branch=1&nd=1" target="_blank" rel="noopener noreferrer"><img class="icon" src="@/assets/img/spotfy.svg" alt="spotfy">podcast</a></li>
                </ul>
            </div>
        </div>
    </footer>
</template>

<script>
export default {

}
</script>

<style scoped>
    @import '../app.scss';   

footer{
    
    margin-top: 5px;
    background-color: #050530;
    color: #EDF0F2;
    font-size: 12px;
}

a{
    text-decoration: none;
    color: #EDF0F2;
}


.caixa ul{
    list-style: none;
}
.caixa li{
    margin-bottom: 15px;
}

.icon{
    padding-right: 8px;
}

.logo-footer{grid-area: logo;
    margin-top: 30px;
}
#address{
    grid-area: address;
    margin-top: 15px;
    margin-left: 50px;
}
#l1{grid-area: li;
    margin-top: 10px;
    margin-left: 20px;
}
#l2{grid-area: l2;
margin-top: 10px;}

.caixa{
    padding: 4vh  15vw  4vh 15vw;
    display: grid;
    grid-template-areas:  "logo address li l2";

}

@media (max-width: 999px) {
    
.caixa{
    margin: auto;
    display: block;
    

}

.logo-footer, #address{
    padding: 0  0  4vh 0;
}

#address, #l1, #l2 {
      margin: 0px;
}

.caixa ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}


}
</style>