<template>
  <div class="box">
      <section id="section1">
          <div id="item1">
                <div class="line"></div>
                <h4 class="text-item">Sobre ETC Digital</h4>
                <p>Observamos que os clientes têm demandas que vão além da consultoria e mentoria. Eles precisam capacitar seus colaboradora es, trazer temas para discussões amplas dentro das organizações e gerar conhecimento. A ETC academy nasce nesse momento. Um portfólio completo de cursos, workshops, treinamentos para empresas. Somos atentos às mudanças no mundo dos negócios, no marketing e na sociedade. Temos a curadoria de professores, mestres e doutores com ampla experiência de mercado com visões reticulares sobre o mundo dos negócios e os novos caminhos trazidos pela transformação digital. </p>
          </div>
          <div id="item2">
              <img class="photo" src="@/assets/img/CongressoRondoniense.jpg" alt="">
          </div>
           
          <div id="item4">
              <div class="line"></div>
                <h4 class="text-item">Quem gerencia os projetos da ETC Digital</h4>
                <p>Professor Alysson Lisboa Neves é jornalista formado pelo Uni-BH. Especialista em Produção em Mídias Digitais pelo IEC PUC Minas e Mestre em Comunicação Digital Interativa pela Universitat de Vic, Barcelona - Espanha.</p>
                <p>Mestre em Comunicação Social pela PUC Minas. Especialista em  Digital Estrategies Business for Business: Leading the Next-Generation Enterprise - Columbia Business School - EUA. Trabalhou por 20 anos nos jornais Estado de Minas, Hoje em Dia e Revista Encontro, onde desempenhou as funções de editor de novas mídias e coordenador da equipe digital.</p>
                <p>É professor e Coordenador no IEC Puc Minas nos cursos de Marketing e Vendas; MBA em Marketing Estratégico, Gestão Estratégica de Negócios; E-commerce; MBA em Branding; E-Commerce e Negócios Digitais; Empreendedorismo e Modelagem de Negócios, entre outros. Atua como consultor nas áreas ligadas ao jornalismo digital, novas mídias, mídias sociais, marketing digital e transformação digital.</p>
                <p>Editor do blog Vida Digital do Portal UAI/Estado de Minas. Trabalhou como consultor Sebrae/MG nas cidades de Divinópolis, Nova Serrana/MG e Carmo do Cajuru, atendendo mais de 40 empresas.</p>
                
                
          </div>
          <div  id="item3">
             <img class="photo" src="@/assets/img/AlyssonLisboa.png" alt="">
          </div>
          
      </section>
      <div class="redes d-flex ">
                    <div class="redes-icon">
                      <a href="https://www.linkedin.com/in/alyssonlisboa/" target="_blank" rel="noopener noreferrer"><img src="@/assets/img/linkedin-gray.svg" alt=""></a>
                      <a href="https://open.spotify.com/show/6u4pesa1L3mUvsHdkkKHCu?si=pIz7fOQNQLeZTKqSezDWUw&dl_branch=1&nd=1" target="_blank" rel="noopener noreferrer"><img class="px-4" src="@/assets/img/spotify.svg" alt=""></a>
                    </div>
                </div>
      <contato 
      :card="true" 
      text="Não é possível realizar uma transformação digital eficiente sem cuidar da transformação cultural dentro das organizações"
      id="contato"/>
  </div>
</template>

<script>
  import contato from '../components/contato.vue'
export default {
  components: {
    contato
  }
}
</script>

<style scoped>
@import '../app.scss'; 

.box{
  margin: 4vh 15vw;
  max-width: 70vw;
}

.line{
  background-color: #F18A00;
  border-radius: 8px;
  width: 73px;
  height: 10px;
  margin: 0 0 20px 0;
}

.photo{
    width: 30vw;
}

#item1{grid-area: i1; 
    width: 30vw;

}
#item2{grid-area: i2;}
#item3{grid-area: i3;
  
}
#item4{grid-area: i4;
    width: 30vw;
}

#section1{
  color: #6B787F;
  margin-top: 100px;
  width: 65vw;
  display: grid;
  grid-template-areas:  "i1 i2"
                        "i3 i4";

  grid-row-gap: 80px;
  grid-column-gap: 8vw;
}

.text-item{
    width: 350px;
}

p{
    font-size: 15px;
}

#contato{
    margin: 100px 0;
}

.redes{
    position: relative;
    width: 68.3vw

}

.redes-icon{
  position: absolute;
  left: 38vw;
  top: -15px;
}
@media (max-width: 900px) {
    #section1{
  display: block;
}
.box{
  margin: 4vh 5vw;
  
}
.photo, #item1, #item4{
    width: 88vw;
}


#item1, #item4{
    padding: 35px 0 0 0;
}

.photo{
    padding: 20px 0 0 0;
}

#section1{
    margin: 0;
}
.redes-icon{
  position: absolute;
  left: 0;
  top: 20px;
}
}


@media (max-width: 450px) {
  .text-item{
    width: 90vw;
}
}
</style>