import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Palestras from '../views/Palestras.vue'
import Workshops from '../views/Workshops.vue'
import Customizados from '../views/Customizados.vue'
import Sobre from '../views/Sobre.vue'
import Palestra1 from '../views/Palestras/Era-das-efemeridades-em-um-mundo-volatil.vue'
import Palestra2 from '../views/Palestras/Transformacao-digital-e-os-dominios-da-estrategia.vue'
import Palestra3 from '../views/Palestras/Inovacao-como-uma-forte-aliada.vue'
import Palestra4 from '../views/Palestras/Marketing-digital-estrategico-com-foco-em-vendas.vue'
import Palestra5 from '../views/Palestras/O-papel-dos-colaboradores-na-reputacao-corporativa.vue'
import Palestra6 from '../views/Palestras/gestao-do-tempo-e-produtividade.vue'
import Palestra7 from '../views/Palestras/antifragilidade-e-resiliencia-nas-empresas.vue'
import Palestra8 from '../views/Palestras/comunicaco-e-diversidade-nas-organizacoes.vue'
import Palestra9 from '../views/Palestras/gestao-financeira-do-caos-ao-lucro.vue'
import Palestra10 from '../views/Palestras/educacao-financeira-o-poder-dos-sonhos.vue'
import Workshops1 from '../views/Workshops/customer-centricity.vue'
import Workshops2 from '../views/Workshops/inovar-dentro-das-organizacoes.vue'
import Workshops3 from '../views/Workshops/tendencias-e-estrategias-ESG-para-as-organizacoes.vue'
import Workshops4 from '../views/Workshops/os-6-pilares-da-gestao-financeira.vue'
import Workshops5 from '../views/Workshops/precificacao-lucrativa.vue'




const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/palestras',
    name: 'palestras',
    component: Palestras,
  },
  {
    path: '/workshops',
    name: 'workshops',
    component: Workshops,
  },
  {
    path: '/sobre',
    name: 'sobre',
    component: Sobre,
  },
  {
    path: '/customizados',
    name: 'customizados',
    component: Customizados,
  },
  {
    path: '/palestras/Era-das-efemeridades-em-um-mundo-volatil',
    name: 'Era Das E femeridades Em Um MundoVolátil',
    component: Palestra1,
  },
  {
    path: '/palestras/Transformacao-digital-e-os-dominios-da-estrategia',
    name: 'Transformação digital e os domínios da estratégia',
    component: Palestra2,
  },
  {
    path: '/palestras/Inovacao-como-uma-forte-aliada',
    name: 'Inovação como uma forte aliada',
    component: Palestra3,
  },
  {
    path: '/palestras/Marketing-digital-estrategico-com-foco-em-vendas',
    name: 'Marketing digital estratégico com foco em vendas',
    component: Palestra4,
  },
  {
    path: '/palestras/O-papel-dos-colaboradores-na-reputacao-corporativa',
    name: 'O papel dos colaboradores na reputação corporativa',
    component: Palestra5,
  },
  {
    path: '/palestras/gestao-do-tempo-e-produtividade',
    name: 'Gestão do tempo e produtividade',
    component: Palestra6,
  },
  {
    path: '/palestras/antifragilidade-e-resiliencia-nas-empresas',
    name: 'Antifragilidade e resiliência nas empresas',
    component: Palestra7,
  },
  {
    path: '/palestras/comunicaco-e-diversidade-nas-organizacoes',
    name: 'Comunicação e diversidade nas organizações',
    component: Palestra8,
  },
  {
    path: '/palestras/gestao-financeira-do-caos-ao-lucro',
    name: 'Gestão financeira: do caos ao lucro',
    component: Palestra9,
  },
  {
    path: '/palestras/educacao-financeira-o-poder-dos-sonhos',
    name: 'Educação Financeira: o poder dos sonhos',
    component: Palestra10,
  },
  {
    path: '/workshop/customer-centricity',
    name: 'Customer Centricity',
    component: Workshops1,
  },
  {
    path: '/workshop/inovar-dentro-das-organizacoes',
    name: 'Inovar dentro das organizações',
    component: Workshops2,
  },
  {
    path: '/workshop/tendencias-e-estrategias-ESG-para-as-organizacoes',
    name: 'Tendências e estratégias ESG para as organizações',
    component: Workshops3,
  },
  {
    path: '/workshop/os-6-pilares-da-gestao-financeira',
    name: 'Os 6 pilares da gestão financeira',
    component: Workshops4,
  },
  {
    path: '/workshop/precificacao-lucrativa',
    name: 'Precificação lucrativa',
    component: Workshops5,
  }
]



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
