<template>
  <div class="box">
   <header>
     <nav class="nav-header">
       <h2 class="textorange">Palestras</h2>
       <ul>
         <li><a class="nav-link h2" href="/workshops">Workshops</a></li>
        <li><a class="nav-link h2" href="/customizados">Customizados</a></li>
       </ul>
     </nav>
   </header>
   <hr>


   <section>
     <div class="row">
       <div class="col ">
         <a href="/palestras/Era-das-efemeridades-em-um-mundo-volatil">
         <div class="card">
           <img src="@/assets/img/palestras/EraDaEfemeridades.png" alt="">
           <div class="card-body">
             <div class="line"></div>
             <span class="category">Temática: <span class="textorange">Inovação</span></span>
             <h4>Era das efemeridades em um mundo volátil</h4>
             <img class="saibaMais" src="@/assets/img/saibaMais.svg" alt="">
           </div>
         </div>
         </a>
       </div>
        <div class="col ">
          <a href="/palestras/Transformacao-digital-e-os-dominios-da-estrategia">
         <div class="card">
           <img src="@/assets/img/palestras/Transformacao-digital.png" alt="">
           <div class="card-body">
             <div class="line"></div>
             <span class="category">Temática: <span class="textorange">Inovação</span></span>
             <h4>Transformação digital e os domínios da estratégia</h4>
             <img class="saibaMais" src="@/assets/img/saibaMais.svg" alt="">
           </div>
         </div>
         </a>
       </div>
        <div class="col ">
          <a href="/palestras/Inovacao-como-uma-forte-aliada">
         <div class="card">
           <img src="@/assets/img/palestras/Inovacao.png" alt="">
           <div class="card-body">
             <div class="line"></div>
             <span class="category">Temática: <span class="textorange">Imagem, reputação das marcas</span></span>
             <h4>Inovação como uma forte aliada</h4>
             <img class="saibaMais" src="@/assets/img/saibaMais.svg" alt="">
           </div>
         </div>
         </a>
       </div>



       <div class="col ">
         <a href="/palestras/O-papel-dos-colaboradores-na-reputacao-corporativa">
         <div class="card">
           <img src="@/assets/img/palestras/papel-dos-colaboradores.png" alt="">
           <div class="card-body">
             <div class="line"></div>
             <span class="category">Temática: <span class="textorange">Imagem, reputação das marcas</span></span>
             <h4>O papel dos colaboradores na reputação corporativa</h4>
             <img class="saibaMais" src="@/assets/img/saibaMais.svg" alt="">
           </div>
         </div>
         </a>
       </div>
        <div class="col ">
          <a href="/palestras/Marketing-digital-estrategico-com-foco-em-vendas">
         <div class="card">
           <img src="@/assets/img/palestras/Estratégico-com-foco.png" alt="">
           <div class="card-body">
             <div class="line"></div>
             <span class="category">Temática: <span class="textorange">Marketing Digital</span></span>
             <h4>Marketing digital estratégico com foco em vendas</h4>
             <img class="saibaMais" src="@/assets/img/saibaMais.svg" alt="">
           </div>
         </div>
         </a>
       </div>
        <div class="col ">
          <a href="/palestras/gestao-do-tempo-e-produtividade">
         <div class="card">
           <img src="@/assets/img/palestras/Gestão-do-tempo.png" alt="">
           <div class="card-body">
             <div class="line"></div>
             <span class="category">Temática: <span class="textorange">Pessoas</span></span>
             <h4>Gestão do tempo e produtividade</h4>
             <img class="saibaMais" src="@/assets/img/saibaMais.svg" alt="">
           </div>
         </div>
         </a>
       </div>



       <div class="col">
         <a href="/palestras/antifragilidade-e-resiliencia-nas-empresas">
         <div class="card">
           <img src="@/assets/img/palestras/Antifragilidade-e-resiliência.png" alt="">
           <div class="card-body">
             <div class="line"></div>
             <span class="category">Temática: <span class="textorange">Pessoas</span></span>
             <h4>Antifragilidade e resiliência nas empresas</h4>
             <img class="saibaMais" src="@/assets/img/saibaMais.svg" alt="">
           </div>
         </div>
         </a>
       </div>
        <div class="col">
          <a href="/palestras/comunicaco-e-diversidade-nas-organizacoes">
         <div class="card">
           <img src="@/assets/img/palestras/Comunicação-e-diversidade.png" alt="">
           <div class="card-body">
             <div class="line"></div>
             <span class="category">Temática: <span class="textorange">Pessoas</span></span>
             <h4>Comunicação e diversidade nas organizações</h4>
             <img class="saibaMais" src="@/assets/img/saibaMais.svg" alt="">
           </div>
         </div>
         </a>
       </div>

       <div class="col">
          <a href="/palestras/gestao-financeira-do-caos-ao-lucro">
         <div class="card">
           <img src="@/assets/img/palestras/gestao.jpeg" alt="">
           <div class="card-body">
             <div class="line"></div>
             <span class="category">Temática: <span class="textorange">Imagem, reputação das marcas</span></span>
             <h4>Gestão financeira: do caos ao lucro</h4>
             <img class="saibaMais" src="@/assets/img/saibaMais.svg" alt="">
           </div>
         </div>
         </a>
       </div>

       <div class="col">
          <a href="/palestras/educacao-financeira-o-poder-dos-sonhos">
         <div class="card">
           <img src="@/assets/img/palestras/EducacaoFinanceira.jpeg" alt="">
           <div class="card-body">
             <div class="line"></div>
             <span class="category">Temática: <span class="textorange">Pessoas</span></span>
             <h4>Educação Financeira: o poder dos sonhos</h4>
             <img class="saibaMais" src="@/assets/img/saibaMais.svg" alt="">
           </div>
         </div>
         </a>
       </div>

       <div class="col col-none"></div>
     
       </div>

   </section>

  <section id="cta">

    <h2>CAPACITAÇÃO E DESENVOLVIMENTO HUMANO</h2>
    <h4>Faça o download do nosso Portfólio 2022</h4>
    <a href="Portfolio.pdf" download class="btn btn-big orange btn-p">Baixar</a>
  </section>

   <nav class="navFooter">
     <h2 class="textorange outros">Palestras</h2>
       <ul class="navFooter-liks">
         <li><a class="nav-link" href="/workshops">Ver Workshops <img class="arrow" src="@/assets/img/arrow.svg" alt=""></a></li>
        <li><a class="nav-link" href="/customizados">Ver Customizados <img  class="arrow " src="@/assets/img/arrow.svg" alt=""></a></li>
       </ul>
     </nav>

      <nav class="navFooter navFooter2">
      
       <ul class="navFooter-liks">
         <li><a class="nav-link" href="/palestras">Outras palestras </a></li>
         <li><a class="nav-link" href="/workshops">Ver Workshops </a></li>
        <li><a class="nav-link" href="/customizados">Ver Soluções Customizados </a></li>
        <div class="arrow-div">
          <a href="/palestras"><img class="arrow " src="@/assets/img/arrow.svg" alt=""></a>
          <a href="/workshops"><img class="arrow" src="@/assets/img/arrow.svg" alt=""></a>
          <a href="/customizados"><img  class="arrow" src="@/assets/img/arrow.svg" alt=""></a>
        </div>
       </ul>
     </nav>
     <hr>
     
 
   <contato 
    :card="false"
    text="O conhecimento transforma as pessoas. As pessoas transformam os negócios"
    id="contato"/>

  </div>
  
</template>

<script>
import contato from '../components/contato.vue'
export default {
  components: {
    contato
  },
}
</script>

<style scoped>
a{
    text-decoration: none;

}
nav{
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 50px;
}
h2{

  margin-top: 9px;
}
nav ul{

  display: flex;
  list-style: none;
}

.textorange{
  color: #F18A00;
}

.nav-link{
    color: #050530;
}

hr{
  padding: 1px;
  background-color: #F18A00;
}

.line{
  background-color: #F18A00;
  border-radius: 8px;
  width: 73px;
  height: 8px;
  margin: 19px 0 5px 0px;
}

.card{
  width: 299px;
height: 490px;
border: 0;

}

img{
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 205px;
}

.row{
  display: flex;
  justify-content: space-between;
}

.col{
  padding: 0;
  padding-bottom: 40px;
  max-width: 299px;

  
  
}

.card-body{
  color: #FFF;
  background: #3C474E;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 5px 30px;
}

h4{
 margin-top: 30px;
 font-size: 20px;
 
}

.saibaMais{
position: absolute;
bottom: 20px;
right: 25px;
  width: 110px;
  height: 40px;
}

.arrow{
  width: 20px;
}

.navFooter{
  margin: 20px 0 50px 0;
  position: relative;
}

.navFooter2{
  display: none;
}

.navFooter-liks{
position: absolute;
top: -50px;
right: 0;
}
.col-end{
  margin: auto;

  
}
.outros{
  position: absolute;
top: 30px;

  padding: 0;

}

#cta{
  text-align: center;
  margin: 50px 0 30px 0;
}

.btn{
  width: 20vw;
  height: 40px;
  border-radius: 50px;
  margin-top: 20px;
}

@media (max-width: 1271px) {
  .col-none{
    display: none;
  }
}

@media (max-width: 999px) {
   #cta{
    
  width: 87vw;

}
   .navFooter{
    width: 87vw;
  }
  hr{
  width: 87vw;
  
}

.nav-header{
  width: 87vw;
}

.row{
  width: 87vw;
}
}
@media (max-width: 700px) {
  #cta{
 
  margin-bottom: 100px;
}
  img{
  width: 100%;
}
  nav{
    display: none;
  }
  .col{
  margin-left: 2vh;
  min-width: 87vw;
  
}
.col-6{
  margin: 0;
}
.card{
  margin: auto;
  width: 400px;
}
.navFooter{
  display: none;
}
.navFooter2{
  margin: 50px 0;
}
.navFooter-liks, .navFooter2{
display: block;
  position: relative;
}

nav ul{
  margin-top: 10px;
  display: block;
  list-style-type: none;
  padding: 0;
  margin-left: -14px;
}

.arrow-div{
  
  position: absolute;
  top: 0px;
  right: 0vw;

  
  
}

.arrow{
  display: block;
  height: 25px;
  margin: 0px 0 20px 0;
}


}

@media (max-width: 450px) {
  .card{
  margin: auto;
  width: 90vw;
}
}
</style>