<template>
<section id="carroselFotos">
      <swiper
    :modules="modules"
    :slides-per-view="slidesPer"
    :space-between="40"
    :autoplay="autoplay"
    navigation
    @swiper="onSwiper"
    @slideChange="onSlideChange"
  >
    <swiper-slide> <div class="card">
          <div class="text">
            <img class="iconS1" src="@/assets/img/Group770.svg" alt="">
            <h5><strong>Conteúdo</strong></h5>
            <p>Curadoria de conteúdo que envolve professores de importantes instituições de ensino e pesquisa, além de
              profissionais de renome no mercado.</p>
          </div>
        </div>
        </swiper-slide>
    <swiper-slide><div class="card">
          <div class="text">
            <img class="iconS1" src="@/assets/img/alvo.svg" alt="">
            <h5><strong>Assertividade</strong></h5>
            <p>Entregas diferentes para os três níveis organizacionais - Estratégico, tático e Operacional.</p>
          </div>
        </div>

        </swiper-slide>
    <swiper-slide><div class="card">
          <div class="text">
            <img class="iconS1" src="@/assets/img/Profundidade.svg" alt="">
            <h5><strong>Profundidade</strong></h5>
            <p>Os trabalhos são elaborados levando em conta os diversos níveis de profundidade e entrega</p>
          </div>
        </div>
       </swiper-slide>
    <swiper-slide> <div class="card">
          <div class="text">
            <img class="iconS1" src="@/assets/img/Imersão.svg" alt="">
            <h5><strong>Imersão</strong></h5>
            <p>Os trabalhos são elaborados de modo que os participantes possam interagir e ampliar discussões</p>
          </div>
        </div></swiper-slide>
    ...
  </swiper>
    </section>
</template>

  

<script>

  import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
  import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
  import 'swiper/swiper.min.css';
  import 'swiper/modules/autoplay/autoplay.min.css'
  import { useWindowSize } from 'vue-window-size';

  export default {
    components: {
      Swiper,
      SwiperSlide,
    },
    data(){
      const { width, height } = useWindowSize();
      return{
        windowWidth: width,
        windowHeight: height,
        slidesPer: 1.5,
        autoplay: {
        delay: 4000,
      },
      }
    },
    setup() {
      
      return {
        modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay],
      };
    },

    watch: {
        windowWidth(newval) {
            if(newval <= 450) this.slidesPer = 1
            else this.slidesPer = 1.5
        }
    },
  };
</script>

<style scoped>

.card{
  margin: 32px 0px;
 width: 280px;
height: 300px;
background: #FFFFFF;
/* Cinza light */

border: 1px solid #EDF0F2;
box-sizing: border-box;
border-radius: 16px;
  
}
.iconS1{
  width: 55px;
  margin-bottom: 24px;
}
.text{
  margin: 32px;
}

@media (max-width: 450px) {
  .card{
  margin: 32px 0px;
 width: 230px;
 }
p{
  font-size: 14px;
}


}

</style>