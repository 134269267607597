<template>
<div>
    <section class="contato" v-if='card'>
     <div class="cardGray">
         <div >
           <div class="line"></div>
            <h3 id="titleItem6">"Não é possível realizar uma transformação digital eficiente sem cuidar da transformação cultural dentro das organizações"</h3>
            <p class="h5 mt-4"><strong>Alysson Lisboa</strong></p>
         </div>
         
      </div>
      <div class="cardContato">
        <h4 class=" h2 textOrigin">Entre em contato</h4>
        <span>Whatsapp</span>
        <p>(31) 99831-6905</p>
        <span>E-mail</span>
        <p>alysson@etcdigital.etc.br</p>
      </div>
    </section>

    <section class="contato" v-else>
      <div class="cardContato">
        <h4 class=" h2 textOrigin">Entre em contato</h4>
        <span>Whatsapp</span>
        <p>(31) 99831-6905</p>
        <span>E-mail</span>
        <p>alysson@etcdigital.etc.br</p>
      </div>

      <div class="cardGray">
         <div class="cardGray-text">
           <div class="line"></div>
            <h3 id="titleItem6">"{{text}}"</h3>
            <p class="h5 mt-4"><strong>Alysson Lisboa</strong></p>
  
         </div>
      </div>
    </section>
</div>
  
</template>

<script>
export default {
    props: {
    card: {
      type: Boolean,
      default: false,
    },
    text: String,
  },
}
</script>

<style scoped>
.textOrigin{
  color: #F18A00;
}
.line{
  background-color: #F18A00;
  border-radius: 8px;
  width: 73px;
  height: 10px;
  margin: 32px 0px;
}

.cardGray{
  padding: 30px 068px 68px 68px;
  grid-area: i1;
  display: flex;
  justify-content: space-between;
  background-color: #EDF0F2;
  border-radius: 20px;
  
  width: 42vw;
  
  height: 380px;
  color: #3C474E;
    grid-area: card1;
}

.cardContato{
  width: 335px; 
  height: 380px;
  background-color: #2F2F2F;
  border-radius: 20px;
  color: #FFF;
  grid-area: card2;

}

.cardContato h4{
  
  padding: 70px 0 20px 0;
  text-align: center;
}
.cardContato p{
  font-size: 20px;
  padding: 0 20px 20px 45px; 
}
.cardContato span{
  color: #6B787F;
  font-size: 15px;
  padding: 0 20px 20px 45px; 
}

.contato{
  display: flex;
  justify-content: space-between;
  width: 68.3vw;
}

.contato2 .cardContato{
    margin: 0;
}

@media (max-width: 1120px) {
   h3{
    font-size: 23px;
  }
}
@media (max-width: 999px) {
  h3{
    font-size: 25px;
  }
    .contato{
  display: block;
  margin: 30px 0;
}

    .cardContato{
  width: 87vw; 
  height: 380px;
  margin: 30px 0 30px 0;
}
    .cardGray{
    
  width: 87vw;
  height: 380px;
  
}
.line{
  margin: 15px 0px;
}

}

@media (max-width: 450px) {
  .cardGray{
  padding: 30px 30px 0 30px;
}
h3{
    font-size: 23px;
  }
 .cardContato p{
  font-size: 20px;
  padding: 0 20px 20px 30px; 
}
.cardContato span{
  color: #6B787F;
  font-size: 15px;
  padding: 0 20px 20px 30px; 
}
}
</style>