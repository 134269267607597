<template>
<section id="carroselFotos">
      <swiper
      id='swiper1'
    :modules="modules"
    :slides-per-view="3.5"
    :space-between="40"
    :autoplay="autoplay"
    navigation
    @swiper="onSwiper"
    @slideChange="onSlideChange"
  >
    <swiper-slide><img class="swiperphoto" src="@/assets/img/carousel/IMG_2309.jpg" alt=""></swiper-slide>
    <swiper-slide><img class="swiperphoto" src="@/assets/img/carousel/IMG_2273.jpg" alt=""></swiper-slide>
    <swiper-slide><img class="swiperphoto" src="@/assets/img/carousel/_DSC6586.jpg" alt=""></swiper-slide>
    <swiper-slide><img class="swiperphoto" src="@/assets/img/carousel/carrosel04.png" alt=""></swiper-slide>
   <swiper-slide><img class="swiperphoto" src="@/assets/img/carousel/carrossel05.jpg" alt=""></swiper-slide>
    ...
  </swiper>
  <swiper
  id='swiper2'
    :modules="modules"
    :slides-per-view="1"
    :space-between="40"
    navigation
    :autoplay="autoplay"
    @swiper="onSwiper"
    @slideChange="onSlideChange"
  >
    <swiper-slide><img class="swiperphoto" src="@/assets/img/carousel/IMG_2309.jpg" alt=""></swiper-slide>
    <swiper-slide><img class="swiperphoto" src="@/assets/img/carousel/IMG_2273.jpg" alt=""></swiper-slide>
    <swiper-slide><img class="swiperphoto" src="@/assets/img/carousel/_DSC6586.jpg" alt=""></swiper-slide>
    <swiper-slide><img class="swiperphoto" src="@/assets/img/carousel/carrosel04.png" alt=""></swiper-slide>
   <swiper-slide><img class="swiperphoto" src="@/assets/img/carousel/carrossel05.jpg" alt=""></swiper-slide>
    ...
  </swiper>
    </section>
</template>

  

<script>

  import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
  import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
  import 'swiper/swiper.min.css';
  import 'swiper/modules/autoplay/autoplay.min.css'

  export default {
    components: {
      Swiper,
      SwiperSlide,
    },
    data(){
      return{
        autoplay: {
        delay: 2000,
      },
      }
    },
    setup() {

      return {
        modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay],
      };
    },
  };
</script>

<style scoped>

.swiperphoto{
  width: 28.5vw;
  height: 299px;
  border-radius: 15px ;
  
}
#swiper2{
  display: none;
}
@media (max-width: 900px) {
  :root{
    --width: 900;
}
#swiper1{
  display: none;
}
#swiper2{
  display: block;
  text-align: center;
}
.swiperphoto{

  width: 440px;
  
}
}
@media (max-width: 450px) {
  .swiperphoto{

  width: 300px;
  height: 200px;
  
}
}
</style>