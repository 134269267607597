<template>
  <div class="box">
      <section id='banner'>
      <div class="retangulo">
        <div class='text-banner'>
            <div class="line"></div>
            <h2>Inovar dentro das organizações:</h2>
            <p class="h5">Como a inovação pode acontecer dentro das empresas e envolver os colaboradores no processo de mudança</p>
        </div>
        </div>
      <div class="bannerimg"></div>
    </section>

   

    <div class="grid">
      <article>
         <span class="category">Temática: <span class="textorange">Inovação</span></span>
         <h4>Público-Alvo</h4>
          <p>Equipe de diversas áreas das empresas</p>        

        <li class="textorange h3"><span class="h4">Abordagem</span></li>
          <p>Dinâmica para buscar soluções aplicáveis dentro das organização. Ou a partir de um problema apresentado pelos colaboradores ou a partir das boas práticas do mercado.</p>

           <li class="textorange h3"><span class="h4">Resultados esperados</span></li>
          <p>Aumento considerável do engajamento dos funcionários. Quebra de objeções e paradigmas em relação à inovação. Reconhecimento que os erros fazem parte do processo de aprendizagem empreendedora e compreensão da dinâmica do “falhar rápido e aprender rápido”. Além da compreensão sobre o ciclo da inovação (construir, medir e aprender).</p>

        <li class="textorange h3"><span class="h4">Tópicos que serão abordados</span></li>
          <p>- Tipos de inovação <br>
            - Metodologias ágeis<br>
            - Dor do cliente<br>
            - Matriz da inovação<br>
            - Ferramentas para inovação<br>
            - Problem Solving</p>
        
      </article>
      <div id="Contato">
          <h4 class=" h2">Entre em contato</h4>
          <span>Whatsapp</span>
          <p>(31) 99831-6905</p>
          <span>E-mail</span>
          <p>alysson@etcdigital.etc.br</p>
      </div>
    </div>


    <nav class="navFooter">
      <a class="nav-link outros" href="/workshops">Outros Workshops <img class="arrow" src="@/assets/img/arrow.svg" alt=""></a>
       <ul class="navFooter-liks">
         <li><a class="nav-link" href="/palestras">Ver Palestras<img class="arrow" src="@/assets/img/arrow.svg" alt=""></a></li>
        <li><a class="nav-link" href="/customizados">Ver Customizados <img  class="arrow" src="@/assets/img/arrow.svg" alt=""></a></li>
       </ul>
     </nav>

  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .line{
  background-color: #F18A00;
  border-radius: 8px;
  width: 60px;
  height: 8px;
  margin: 14px 0px;
}



#banner{
  width: 70vw;

}
.retangulo{
  padding: 50px 0 0 0;
position: absolute;
width: 70vw;
height: 319px;
border-radius: 20px;

background: linear-gradient(270.98deg, #00C2FF -22.82%, #050530 109.57%);
mix-blend-mode: normal;
opacity: 0.9;
}

.bannerimg{
width: 70vw;
height: 319px;
background: url(../../assets/img/workshops/Inovar-grande.png);
background-repeat: no-repeat;
background-size: 70vw 319px;
border-radius: 20px;

}

.text-banner{
  margin: 50px 32VW 0 50px;
  color: #FFFFFF;
  }

  .text-banner h2{
    font-weight: bold;
  }
.container{
  margin: 4vh 15vw;
}


#Contato{
  width: 19vw; 
  height: 302px;
  margin-right: 160px;
  background-color: #EDF0F2;
  border-radius: 20px;
  color: #3C474E;
  grid-area: contato;
  

}

#Contato h4{
  font-size: 25px;
  padding: 50px 0 20px 0;
  text-align: center;
}
#Contato p{
  font-size: 20px;
  padding: 0 20px 20px 45px; 
}
#Contato span{
  color: #6B787F;
  font-size: 15px;
  padding: 0 20px 20px 45px; 
}

article{
  width: 39vw;
  grid-area: article;
}

.grid{
  display: grid;
  grid-template-areas:  "article  contato";

  grid-column-gap: 11.7vw;
  margin: 70px 0 0 0;
}
.h4{
   color: #050530;
}

p{
  margin: 10px 0 50px 0;
}

h4{
  padding-top: 10px;
}

.navFooter{
  margin: 20px 0 50px 0;
  position: relative;
}

.navFooter-liks{
position: absolute;
top: 0px;
right: 0;
}

nav ul{

  display: flex;
  list-style: none;
}


.nav-link{
    color: #050530;
}


.outros{
  padding: 0;
}

#Contato p{
  font-size: 20px;
  padding: 0 20px 20px 45px; 
  margin: 0;
}

@media (max-width: 900px) {
   .grid{
  display: block;
}
.box{
  margin: 4vh 5vw;
  
}

.retangulo, .bannerimg, #banner{

width: 87vw;

}

.bannerimg{
  background-size:  87vw 319px;
  background: url(../../assets/img/workshops/Inovar.png);
    background-repeat: no-repeat;
    background-size: 87vw 319px;
    border-radius: 20px;;
}

.text-banner{
  margin: 50px 15VW 0 50px;
  color: #FFFFFF;
  }

article{
  width: 85vw;
}
#Contato{
  margin: 70px 0;
  width: 85vw;
}
.navFooter{
  margin: 50px 0;
}
.navFooter-liks, .navFooter{
display: block;
  position: relative;
}

nav ul{
  margin-top: 10px;
  display: block;
  list-style-type: none;
  padding: 0;
  margin-left: -14px;
}

.arrow{
  position: absolute;
  right: -12vw;
}

.text-banner{
  margin: 0px 32VW 0 50px;
  color: #FFFFFF;
  }
}
</style>