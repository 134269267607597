<template>
<section id="carroselFotos">
      <swiper
    :modules="modules"
    :slides-per-view="1"
    :space-between="40"
    :autoplay="autoplay"
    navigation
    @swiper="onSwiper"
    @slideChange="onSlideChange"
  >
    <swiper-slide> <div class="phrase" id="p1">
          <h5><strong>Rodrigo Ferreira</strong></h5>
          <p>Diretor da Santa Marta Galvanizados</p>
          <p><span class="big-marks"> </span>”O professor Alysson ministrou uma palestra na sede da Indústria Santa Marta para apresentar a nova identidade visual da marca - criada pela sua empresa, a  ETC Digital que está desenhando nosso plano completo de comunicação. O evento trouxe muitos insights para toda a equipe e também ajudou a fortalecer as diretrizes que estamos traçando para o futuro da nossa indústria. Parabéns Alysson pela condução dos trabalhos.<span class="textorange">”</span></p>
        </div>
        </swiper-slide>
    <swiper-slide><div class="phrase" id="p2">
          <h5><strong>Gerente de Operações</strong></h5>
          <p>Grupo Ambar Amaral</p>
          <p><span class="big-marks">“</span>A palestra do professor Alysson Lisboa aqui na sede da Ambar Amaral em Santa Fé do Sul/SP foi muito interessante e abordou temas relacionados ao marketing digital e a transformação digital. Os gestores da empresa puderam conhecer de perto algumas novidades e ferramentas que vão ajudar, com certeza, na melhoria da nossa comunicação e do nosso marketing.<span class="textorange">”</span></p>
        </div>

        </swiper-slide>
    
    ...
  </swiper>
    </section>
</template>

  

<script>

  import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
  import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
  import 'swiper/swiper.min.css';
  import 'swiper/modules/autoplay/autoplay.min.css'

  export default {
    components: {
      Swiper,
      SwiperSlide,
    },
    data(){
      return{
        autoplay: {
        delay: 4000,
      },
      }
    },
    setup() {
      return {
        modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay],
      };
    },
  };
  
</script>

<style scoped>

.textOrigin{
  color: #F18A00;
}

.big-marks{
  color: #F18A00;
  font-size: 30px;
}
P{
  color: #6B787F;
}
.phrase{
  
  margin: 50px 0;
  width: 70vw;
}

.swiper-pointer-events{
  height: 410px;

}

@media (max-width: 900px) {
  .phrase {
    min-width: 87vw;
  }
}
</style>