<template>
  <div class="box">
   <header>
     <nav>
       <h2 class="textorange">Palestras</h2>
       <ul>
         <li><a class="nav-link h2" href="/workshops">Palestras</a></li>
        <li><a class="nav-link h2" href="/palestras">Workshops</a></li>
       </ul>
     </nav>
   </header>
   <hr>


   <section>
     <div class="cardBlack">
         <div id="title-card">
           <div class="line"></div>
            <h3>Juntos, criamos soluções incríveis para a sua organização!</h3>
            <a class="btn btn-big orange btn-p" href="#contato">Entre em contato</a>
         </div>
         <div class="text-card">
             <p>Montamos eventos personalizados que possibilitam a geração de consciência nos colaboradores em um nível mais profundo de entrega. Isso permite que eles desenvolvam autonomia e proatividade na construção e disseminação de um pensamento inovador em suas equipes e organizações.</p>
         </div>
         <div id="list-card">
           <ul>
              <li>Convenções</li>
              <li>Mesas-redondas</li>
              <li>Seminário sobre inovação</li>
              <li>Cursos in company</li>
              <li>Workshops e Oficinas</li>
              <li>Bootcam</li>
              <li>Hackathons</li>
              <li>Pitch Day</li>
           </ul>
         </div>
      </div>

   </section>

   <nav>
       <h2 class="textorange">Palestras</h2>
       <ul class="navFooter">
         <li><a class="nav-link" href="/workshops">Ver Palestras <img class="arrow" src="@/assets/img/arrow.svg" alt=""></a></li>
        <li><a class="nav-link" href="/customizados">Ver Workshops <img  class="arrow" src="@/assets/img/arrow.svg" alt=""></a></li>
       </ul>
     </nav>
     <hr>
   <contato 
    :card="false"
    text="O conhecimento transforma as pessoas. As pessoas transformam os negócios"
    id="contato"/>
  </div>
  
</template>

<script>
import contato from '../components/contato.vue'
export default {
  components: {
    contato
  }
}
</script>

<style scoped>
@import '../app.scss'; 

.box{
  margin: 4vh 15vw;
  max-width: 70vw;
}

nav{
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 50px;
}
h2{

  margin-top: 9px;
}
nav ul{

  display: flex;
  list-style: none;
}

.textorange{
  color: #F18A00;
}

.nav-link{
    color: #050530;
}

hr{
  padding: 1px;
  background-color: #F18A00;
}

.line{
  background-color: #F18A00;
  border-radius: 8px;
  width: 73px;
  height: 8px;
  margin: 19px 0 5px 0px;
}

h4{
 margin-top: 30px;
 font-size: 20px;
 
}

.navFooter{
position: absolute;
top: 20px;
right: 0;
}

.cardBlack{
    display: flex;
padding: 80px;
margin: auto;
color: #FFFFFF;

background: #2F2F2F;
border-radius: 20px;
}

.text-card{
    width: 320px;
     margin: 0 20px;
}

#title-card{
    width: 250px;
    margin: auto;
}

#list-card{
    list-style-position: inside;
    text-align: end;
    margin: auto;
}

.btn-big{
  margin-top: 20px;
  font-size: 12px;
  width: 150px;
  height: 40px;
  padding: 10px;
  border-radius: 15px;
  background-color: #F18A00;
  color: #FFFFFF;
}

section{
    padding: 20px 0;
}

@media (max-width: 999px) {
  nav{
    display: none;
  }
  .box{
  margin: 4vh 5vw;
  
}
  .cardBlack{
  position: relative;
  display: block;
  background-color: #2F2F2F;
  width: 88vw;
  height: 900px;
}

#title-card{
    width: 250px;
     
}

.text-card{
    width: 250px;
    padding: 50px 0;
    margin: auto;
    
}

.btn-p{
  position: absolute;
  bottom: 40px;
  left: 150px;
}
hr{
  width: 87vw;
}

}

@media (max-width: 450px) {
  .cardBlack{
    padding: 20px;
  }
  h3{

 font-size: 25px;
 
}

.text-card{
    width: 230px;
    padding: 50px 0;
    margin: auto;
    
}

.btn-p{
  position: absolute;
  bottom: 40px;
  left: 70px;
}
}
</style>