<template>
<div>
  <div class="box">
    <section id='banner'>
      <div class="retangulo">
        <div class='text-banner'>
          <h2>CAPACITAÇÃO E DESENVOLVIMENTO HUMANO PARA EMPRESAS</h2>
          <p>Ajudamos sua organização a gerar impacto junto aos colaboradores tratando de   temas atuais e relevantes no cenário atual</p>
          <a class="btn btn-big orange btn-banner " href="/palestras">Conhecer o portfólio</a></div>
        </div>
        
      <div class="bannerimg"></div>
    </section>
    <section id="section1">
      <div class="item1">
          <div class="line"></div>
          <h4>Engajar e motivar sua equipe com treinamentos gera resultados efetivos nos negócios </h4>
          <p>Somos um HUB de educação com soluções inovadoras em modelagem de negócios, eventos, treinamentos e palestras.
          </p>
          <p>Nossos professores e mentores utilizam tecnologias e metodologias ativas para abordar temas atuais relativos à inovação, tecnologia, marketing, comunicação e gestão de negócios</p>
        </div>

        <div class="item2">
          <img class="iconS1" src="@/assets/img/Group770.svg" alt="">
          <h5><strong>Conteúdo</strong></h5>
          <p>Curadoria de conteúdo que envolve professores de importantes instituições de ensino e pesquisa, além de
            profissionais de renome no mercado.</p>
        </div>
        <div class="item3">
          <img class="iconS1" src="@/assets/img/alvo.svg" alt="">
          <h5><strong>Assertividade</strong></h5>
          <p>Entregas diferentes para os três níveis organizacionais - Estratégico, tático e Operacional.</p>
        </div>

        <div class="item4">
          <img class="iconS1" src="@/assets/img/Profundidade.svg" alt="">
          <h5><strong>Profundidade</strong></h5>
          <p>Os trabalhos são elaborados levando em conta os diversos níveis de profundidade e entrega</p>
        </div>
        <div class="item5">
          <img class="iconS1" src="@/assets/img/Imersão.svg" alt="">
          <h5><strong>Imersão</strong></h5>
          <p>Os trabalhos são elaborados de modo que os participantes possam interagir e ampliar discussões</p>
        </div>
        
    </section>
    <div class="carouseldiv">
      <div class="item1">
          <div class="line"></div>
          <h4>Engajar e motivar sua equipe com treinamentos gera resultados efetivos nos negócios </h4>
          <p>Somos um HUB de educação com soluções inovadoras em modelagem de negócios, eventos, treinamentos e palestras.
          </p>
          <p>Nossos professores e mentores utilizam tecnologias e metodologias ativas para abordar temas atuais relativos à inovação, tecnologia, marketing, comunicação e gestão de negócios</p>
        </div>
        <carouseldiv />
    </div>

    <section id="section2">
      
        <a href="/palestras">
        <div id="card1" class="card">
          <div class="degrader">
            <h4 class="text-white cardTitle">Palestras</h4>
          <p class="cardText">Sempre abordando temas atuais, apresentações envolventes e técnicas didáticas avançadas, as palestras da ETC academy buscam abordar os temas de modo leve e considerando sempre o público participante, contexto da empresa e os objetivos a serem alcançados.</p>
          <img class="saibaMais" src="@/assets/img/saibaMais.svg" alt="">
          </div>
        </div>
        </a>

        <a href="workshops">
        <div id="card2" class="card">
          <div class="degrader">
            <h4 class="text-white cardTitle">workshops</h4>
          <p class="cardText">As dinâmicas dos workshops são favoráveis para que os participantes possam trocar experiências, trazer pontos de vista e, principalmente, executar tarefas com metodologias ágeis de aprendizagem. Os workshops da ETC academy proporcionam um profundo nível de entrega.</p>
          <img class="saibaMais" src="@/assets/img/saibaMais.svg" alt="">
          </div>
        </div>
      </a>

      <a href="/customizados">
      <div class="card3-block">
        <div id="card3" class="card">
          <h4 class="text-white cardTitle">Customizados</h4>
          <p class="cardText">Montamos eventos personalizados que possibilitam a geração de consciência nos colaboradores em um nível mais profundo de entrega. Isso permite que eles desenvolvam autonomia e proatividade na construção e disseminação de um pensamento inovador em suas equipes e organizações.</p>
          <img class="saibaMais" src="@/assets/img/saibaMais.svg" alt="">
        </div>
      </div>
      </a>
    </section>
    <carousel class="carousel"/>

    <section id="section4">

       <div id="item8">
        <h4 class="mt-2 h2">Você contrata workshops e oficinas para seus colaboradores, mas os resultados são poucos eficientes?</h4>
        <p class="mt-5">Nosso trabalho inclui entender a real demanda e necessidade das organizações para que possamos entregar o máximo de conteúdo e envolvimento dos participantes.</p>
        <a class="btn btn-big orange btn-none" href="#contato">Conhecer o portfólio</a>
      </div>
      <div class="cardBlack">
         <div id="item6">
           <div class="line"></div>
            <h3 id="titleItem6">Juntos, criamos soluções incríveis para a sua organização!</h3>
            <a class="btn btn-big orange btn-p" href="#contato">Saiba mais</a>
         </div>
         
         <div id="item7">
           <ul>
              <li>Convenções</li>
              <li>Mesas-redondas</li>
              <li>Seminário sobre inovação</li>
              <li>Cursos in company</li>
              <li>Workshops e Oficinas</li>
              <li>Bootcam</li>
              <li>Hackathons</li>
              <li>Pitch Day</li>
           </ul>
         </div>
      </div>
     
    </section>

    <section id="section5">
       <div class="phrase" id="p1">
          <h5><strong>Rodrigo Ferreira</strong></h5>
          <p>Diretor da Santa Marta Galvanizados</p>
          <p><span class="big-marks">“</span>O professor Alysson ministrou uma palestra na sede da Indústria Santa Marta para apresentar a nova identidade visual da marca - criada pela sua empresa, a  ETC Digital que está desenhando nosso plano completo de comunicação. O evento trouxe muitos insights para toda a equipe e também ajudou a fortalecer as diretrizes que estamos traçando para o futuro da nossa indústria. Parabéns Alysson pela condução dos trabalhos.<span class="textorange">”</span></p>
        </div>

      <div class="phrase" id="p2">
          <h5><strong>Gerente de Operações</strong></h5>
          <p>Grupo Ambar Amaral</p>
          <p><span class="big-marks">“</span>A palestra do professor Alysson Lisboa aqui na sede da Ambar Amaral em Santa Fé do Sul/SP foi muito interessante e abordou temas relacionados ao marketing digital e a transformação digital. Os gestores da empresa puderam conhecer de perto algumas novidades e ferramentas que vão ajudar, com certeza, na melhoria da nossa comunicação e do nosso marketing.<span class="textorange">”</span></p>
        </div>
    </section>
    <carouseldiv2 class="carouseldiv2"/>

    <contato 
      :card="true" 
      text="Não é possível realizar uma transformação digital eficiente sem cuidar da transformação cultural dentro das organizações"
      id="contato"/>
    
  </div>
  <carouselFoto/>
  </div>
</template>

  

<script>
  import carousel from '../components/carousel.vue'
  import carouselFoto from './carouselFoto.vue'
  import carouseldiv from './carouseldiv.vue'
  import carouseldiv2 from './carouseldiv2.vue'
  import contato from '../components/contato.vue'
  export default {
    components: {
    carousel,
    carouselFoto,
    carouseldiv,
    carouseldiv2,
    contato
  }
    
  };
</script>

<style scoped>
body {
  overflow-x: hidden;
}

a{
    text-decoration: none;

}

#banner {
  width: 70vw;

}

.retangulo {
  position: absolute;
  width: 68.3vw;
  height: 380px;
  border-radius: 20px;

  background: linear-gradient(270.98deg, #00C2FF -22.82%, #050530 109.57%);
  mix-blend-mode: normal;
  opacity: 0.9;
}

.bannerimg {
  width: 68.3vw;
  height: 380px;
  background: url(../assets/img/_DSC6586.png);
  border-radius: 20px;

}

.text-banner {
  margin: 50px 32VW 0 50px;
  color: #FFFFFF;
}

.text-banner h2 {
  font-weight: bold;
}

.line {
  background-color: #F18A00;
  border-radius: 8px;
  width: 73px;
  height: 10px;
  margin: 32px 0px;
}

.item1 {
  grid-area: c1;
  max-width: 200px;
  margin-right: 160px;
}

.item2 {
  grid-area: c2;
}

.item3 {
  grid-area: c3;
}

.item4 {
  grid-area: c4;
}

.item5 {
  grid-area: c5;
}

#section1 {
  color: #6B787F;
  margin-top: 45px;
  width: 68.3vw;
  display: grid;
  grid-template-areas: "c1 c2 c3"
    "c1 c4 c5"
    "c1 c4 c5";

  grid-row-gap: 10px;
  grid-column-gap: 30px;
}

.carouseldiv {
  display: none;
}

.carouseldiv2 {
  display: none;
}

h4 {
  color: #050530;
}

h5 {
  font-size: 18px;
  color: #050530;
}

.iconS1 {
  width: 80px;
  margin-bottom: 20px;
}

p {
  font-size: 12px;
}

.card {
  margin: 32px 0px;
  max-width: 20vw;
  height: 450px;
  background-image: linear-gradient(270.98deg, #00C2FF -22.82%, #050530 109.57%);
  background-image: url(../assets/img/IMG_2179.png);
  color: #FFF;
  background-position: center;
  border-radius: 20px;

}

.degrader {
  color: #FFF;
  width: 20vw;
  height: 450px;
  background-image: linear-gradient(270.98deg, #00C2FF -22.82%, #050530 109.57%);
  opacity: 0.9;
  border-radius: 20px;
}

.saibaMais {
  position: absolute;
  bottom: 50px;
  right: 35px;
  width: 100px;
}


#card1 {
  grid-area: ca1;
}

#card2 {
  grid-area: ca2;
  width: 20vw;
  height: 450px;
  background: url(../assets/img/arte-novosite-2.jpg);
  border-radius: 20px;
  margin-left: 40px;
}

#card3 {
  grid-area: ca3;
  width: 20vw;
  background: #2F2F2F;
  border-radius: 20px;
  margin-left: 40px;
}



#section2 {
  display: flex;
  justify-content: space-between;
  width: 68.3vw;

}

.cardTitle {
  padding: 40px 30px 0 30px;
}

.cardText {
  padding: 40px 30px;
}

.cardBlack {
  grid-area: i1;
  display: flex;
  justify-content: space-between;
  background-color: #2F2F2F;
  border-radius: 20px;
  width: 42vw;
  height: 380px;
  color: #FFF;
}


.carousel{
    min-width: 68.3vw;
}



.textorange {
  color: #F18A00;
}

#item6 {

  width: 20vw;
  height: 45vh;

  padding: 30px 0 68px 68px;
}

#titleItem6 {
  font-size: 26px;
}

.btn-big {
  margin-top: 20px;
  font-size: 12px;
  width: 150px;
  height: 40px;
  padding: 10px;
  border-radius: 15px;
}

#item7 {
  list-style-position: inside;
  font-size: 13px;
  text-align: end;
  margin: 110px 68px 0 0;
}

#item8 {
  grid-area: i2;
  width: 22vw;
  margin-right: 160px;
}

.btn-none {
  display: none;
}

#section4 {
  color: #6B787F;
  margin-top: 45px;
  width: 68.3vw;
  display: grid;
  grid-template-areas: "i1 i2";

  grid-column-gap: 20px;
}

.phrase {
  width: 21vw;
}

#p1 {
  grid-area: p1;
}

#p2 {
  grid-area: p2;
}

#p3 {
  grid-area: p3;
}

#section5 {
  color: #6B787F;
  margin-top: 45px;
  width: 68.3vw;
  display: flex;
  justify-content: space-around;
  margin: 2.2vw 0;
}

.big-marks {
  color: #F18A00;
  font-size: 30px;
}



@media (max-width: 1060px) {

  #section2 {
    display: block;

  }

  .card1and2,
  #card1,
  #card2,
  #card3 {
    display: block;
    margin: 0;
    padding: 0;
  }

  #card1,
  #card2 {
    margin-bottom: 20px;
  }

  .card,
  .degrader,
  #card2,
  #card3 {
    min-width: 68.3vw;
  }

}


@media (max-width: 999px) {

#banner {
  width: 87vw;

}

.retangulo {
  position: absolute;
  width: 87vw;
  height: 380px;
  border-radius: 20px;

  background: linear-gradient(270.98deg, #00C2FF -22.82%, #050530 109.57%);
  mix-blend-mode: normal;
  opacity: 0.9;
}

.bannerimg {
  width: 87vw;
  height: 380px;
  background: url(../assets/img/_DSC6586.png);
  border-radius: 20px;

}

.text-banner {
  width: 50vw;
  margin: 50px 32VW 0 50px;
  color: #FFFFFF;
}

.text-banner h2 {
  font-weight: bold;
}

.btn-banner{
  width: 70vw;
  margin-top: 50px;
  }
  .carouseldiv {
    display: block;
    min-width: 87vw;
  }

  .carousel{
    min-width: 87vw;
  }

  #section1 {
    display: none;
  }

  #section5 {
    display: none;
  }

  .carouseldiv2 {
    display: block;
    min-width: 87vw;
  }

  .text-banner {
    margin: 40px 30px 0 40px;
    color: #FFFFFF;
  }

  .text-banner h2 {
    font-weight: bold;
  }

  .item1 {
    min-width: 87vw;
  }

  .item1 p {
    color: #6B787F;
    margin-top: 15px;
  }

  #section4 {
    display: block;
    width: 87vw;
  }

  #item8 {
    width: 87vw;
    margin-right: 160px;
  }

  .cardBlack {
    position: relative;
    display: block;
    background-color: #2F2F2F;
    width: 87vw;
    height: 600px;
  }

  #item6 {

    width: 70vw;
    height: 250px;

    padding: 20px 0 50px 50px;
  }

  #item7 {
    list-style-position: inside;
    font-size: 15px;
    text-align: end;
    margin: 0px 50px 0 0;
  }

  .btn-p {
    position: absolute;
    bottom: 40px;
    left: 40px;
    width: 70vw;
  }

  .btn-none {
    display: block;
    margin: 50px 0;
  }
  .card,
  .degrader,
  #card2,
  #card3 {
    min-width: 87vw;
    background-repeat: no-repeat;
    background-size: 87vw ;
  }
}
@media (max-width: 450px) {
   .text-banner{
     margin: 40px 30px 0 30px;
   }
  .text-banner h2 {
    width: 60vw;
  font-size: 20px;
}
.text-banner p {
    width: 60vw;
  }

  #item6 {

    width: 80vw;
    height: 250px;

    padding: 20px 0 50px 40px;
  }
}
</style>